import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png';
import MenuBurger from '../../img/svg/MenuBurger';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import az from '../../img/az.png';
import ru from '../../img/ru.png';
import uk from '../../img/uk.png';

const languages = [
  { code: 'az', lang: 'AZ', flag: az },
  { code: 'en', lang: 'EN', flag: uk },
  { code: 'ru', lang: 'RU', flag: ru },
];

const Navbar = () => {
  const content = useSelector(state => state.Data.content);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedLang, setSelectedLang] = useState(() => {
    const savedLang = localStorage.getItem('language');
    return languages.find(l => l.code === savedLang) || languages[0];
  });

  useEffect(() => {
    i18n.changeLanguage(selectedLang.code);
  }, [selectedLang, i18n]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageSelect = (lng) => {
    setSelectedLang(lng);
    i18n.changeLanguage(lng.code);

    localStorage.setItem('language', lng.code);

    // Update the URL based on the selected language
    const currentPath = location.pathname.split('/').slice(2).join('/');
    if (lng.code === 'az') {
      navigate(`/${currentPath}`); // Change to the correct path if the language is Azerbaijani
    } else {
      navigate(`/${lng.code}/${currentPath}`); // Change to the correct path if it's another language
    }

    setIsDropdownOpen(false);
  };

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(selectedLang.code === 'az' ? '/' : `/${selectedLang.code}/`);
    }
  };

  return (
    <nav className="bg-[var(--white)] fixed navbar w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between container md:justify-between">
          <div className="flex flex-1 justify-center md:justify-start">
            <img
              onClick={handleLogoClick}
              className='max-w-[180px] p-[25px_12px_20px] cursor-pointer'
              src={logo}
              alt="Logo"
            />
          </div>
          <div className="flex gap-[2rem] hidden md:flex">
            <NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/` : `/${selectedLang.code}/`}>{t("nav_1")}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/subjects` : `/${selectedLang.code}/subjects`}>{t("nav_2")}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/graduates` : `/${selectedLang.code}/graduates`}>{t("nav_3")}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/schools` : `/${selectedLang.code}/schools`}>{t("nav_4")}</NavLink>

            {/* Language dropdown */}
            <div className="relative">
              <div className="cursor-pointer text-[var(--black)] text-[16px] flex items-center" onClick={toggleDropdown}>
                <img className="h-[20px]" src={selectedLang.flag} alt={selectedLang.lang} />
              </div>

              {isDropdownOpen && (
                <div className="absolute mt-2 w-[50px] bg-white border rounded-md shadow-lg z-10">
                  {languages.map((lng) => (
                    <div
                      key={lng.code}
                      className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLanguageSelect(lng)}
                    >
                      <img src={lng.flag} alt={lng.lang} className="h-[20px]" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="block md:hidden">
            <button onClick={toggleNavbar} className="outline-none text-white p-2 focus:outline-none">
              <MenuBurger />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="p-[0px_35px_10px] md:hidden">
          <div className="flex flex-col gap-[1rem]">
            <button onClick={toggleNavbar}><NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/` : `/${selectedLang.code}/`}>{t("nav_1")}</NavLink></button>
            <button onClick={toggleNavbar}><NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/subjects` : `/${selectedLang.code}/subjects`}>{t("nav_2")}</NavLink></button>
            <button onClick={toggleNavbar}><NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/graduates` : `/${selectedLang.code}/graduates`}>{t("nav_3")}</NavLink></button>
            <button onClick={toggleNavbar}><NavLink className="text-[var(--black)] text-[16px]" to={selectedLang.code === 'az' ? `/schools` : `/${selectedLang.code}/schools`}>{t("nav_4")}</NavLink></button>

            {/* Language dropdown for mobile */}
            <div className="relative flex justify-center"> {/* Center flags */}
              <div className="cursor-pointer text-[var(--black)] text-[16px] flex items-center" onClick={toggleDropdown}>
                <img src={selectedLang.flag} alt={selectedLang.lang} className="w-5 h-5 mr-2" />
              </div>

              {isDropdownOpen && (
                <div className="absolute m-[0_auto] mt-2 w-[50px] bg-white border rounded-md shadow-lg z-10">
                  {languages.map((lng) => (
                    <div
                      key={lng.code}
                      className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLanguageSelect(lng)}
                    >
                      <img src={lng.flag} alt={lng.lang} className="w-5 h-5 mr-2" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
